/**
 * This file contain values from environment variable
 */
import { Stage } from '@efishery/sdk-core';

export const AUTH_V2_STAGE =
  Stage[(import.meta.env.VITE_AUTH_V2_STAGE ?? 'stg') as keyof typeof Stage];
export const AUTH_V2_CLIENT_ID = import.meta.env.VITE_AUTH_V2_CLIENT_ID ?? '';
export const AUTH_V2_DOMAIN = import.meta.env.VITE_AUTH_V2_DOMAIN ?? '';
export const AUTH_SERVICE_URL = import.meta.env.VITE_AUTH_SERVICE_BASE_URL ?? '';

export const APP_NAME = import.meta.env.VITE_APP_NAME ?? '';
export const APP_VERSION = import.meta.env.VITE_APP_VERSION ?? 'preview';
export const APP_COMMIT = import.meta.env.VITE_APP_COMMIT ?? 'xxx';
export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT ?? 'staging';

export const TRANSPORT_MANAGEMENT_SERVICE_URL =
  import.meta.env.VITE_TRANSPORT_MANAGEMENT_SERVICE_URL ?? '';

// Core Service
export const CORE_SUPPORT_SERVICE_URL = import.meta.env.VITE_CORE_SUPPORT_SERVICE_URL ?? '';
// File Manager Service
export const FILE_MANAGER_SERVICE_URL = import.meta.env.VITE_FILE_MANAGER_SERVICE_URL ?? '';

// Flagging Service
export const FLAGGING_SERVICE_URL = import.meta.env.VITE_FLAGGING_SERVICE_URL ?? '';

// Sentry
export const ENABLE_SENTRY = ['true', '1'].includes(import.meta.env.VITE_ENABLE_SENTRY || '');
export const SENTRY_GENERATE_SOURCEMAP = ['true', '1'].includes(
  import.meta.env.VITE_SENTRY_GENERATE_SOURCEMAP || '',
);
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN ?? '';
export const SENTRY_URL = import.meta.env.VITE_SENTRY_URL ?? '';
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN ?? '';
export const SENTRY_ORG = import.meta.env.VITE_SENTRY_ORG ?? '';
export const SENTRY_PROJECT = import.meta.env.VITE_SENTRY_PROJECT ?? '';

// Directly consumed values
export const BULK_RATE_CARD_UPLOAD_GUIDELINE_URL =
  import.meta.env.VITE_BULK_RATE_CARD_UPLOAD_GUIDELINE_URL || '';
export const BLITZ_VENDOR_ID = import.meta.env.VITE_BLITZ_VENDOR_ID || '';

//Feature toggle from flagr
export const ENABLE_VENDOR_MANAGEMENT_LOGISTIC_VENDOR_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_VENDOR_MANAGEMENT_LOGISTIC_VENDOR_FLAGR_ID) ?? 0;
export const ENABLE_LOGISTIC_VENDOR_CONTRACT_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_LOGISTIC_VENDOR_CONTRACT_FLAGR_ID) ?? 0;
export const ENABLE_DELIVERY_ORDER_ORDER_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_DELIVERY_ORDER_ORDER_FLAGR_ID) ?? 0;
export const ENABLE_DELIVERY_ORDER_SHIPMENT_PROCESS_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_DELIVERY_ORDER_SHIPMENT_PROCESS_FLAGR_ID) ?? 0;
export const ENABLE_MANAGEMENT_DRIVER_INTERNAL_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_MANAGEMENT_DRIVER_INTERNAL_FLAGR_ID) ?? 0;
export const ENABLE_MAINTENANCE_MODE_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_MAINTENANCE_MODE_FLAGR_ID) ?? 0;
export const ENABLE_VENDOR_PAYMENT_COMPLETE_DO_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_VENDOR_PAYMENT_COMPLETE_DO_FLAGR_ID) ?? 0;
export const ENABLE_VENDOR_PAYMENT_PAYMENT_PROCESS_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_VENDOR_PAYMENT_PAYMENT_PROCESS_FLAGR_ID) ?? 0;
export const GTM_ID = import.meta.env.VITE_GTM_ID ?? '';
export const ENABLE_GTM = ['true', '1'].includes(import.meta.env.VITE_ENABLE_GTM || '');
export const ENABLE_UPSTREAM_FILTER_PAYMENT_PROCESS = ['true', '1'].includes(
  import.meta.env.VITE_ENABLE_UPSTREAM_FILTER_PAYMENT_PROCESS || '',
);
export const FILE_MANAGER_SERVICE_BUCKET = import.meta.env.VITE_FILE_MANAGER_SERVICE_BUCKET ?? '';
export const ENABLE_FFMS_EXPLICIT_BUCKET = ['true', '1'].includes(
  import.meta.env.VITE_ENABLE_FFMS_EXPLICIT_BUCKET || '',
);
export const ENABLE_UPSTREAM_ACCEPT_ORDER_LAST_MILE_FLAGR_ID =
  Number(import.meta.env.VITE_ENABLE_UPSTREAM_ACCEPT_ORDER_LAST_MILE_FLAGR_ID) ?? 0;
export const ENABLE_REPORT_ADDRESS = ['true', '1'].includes(
  import.meta.env.VITE_ENABLE_REPORT_ADDRESS || '',
);
export const ENABLE_UPSTREAM_ORDER_FEEDER = ['true', '1'].includes(
  import.meta.env.VITE_ENABLE_UPSTREAM_ORDER_FEEDER || '',
);
export const TRANSPORT_MANAGEMENT_SERVICE_URL_V2 =
  import.meta.env.VITE_TRANSPORT_MANAGEMENT_SERVICE_URL_V2 ?? '';
export const ENABLE_SPLIT_ORDER = ['true', '1'].includes(
  import.meta.env.VITE_ENABLE_SPLIT_ORDER || '',
);
export const ENABLE_NEW_UPSTREAM_POL_POD = ['true', '1'].includes(
  import.meta.env.VITE_ENABLE_NEW_UPSTREAM_POL_POD || '',
);
